<template>
    <div class="not-found" data-view>
        <div class="clearfix"></div>

        <b-row>
        <!-- Contenido open y custom programs -->
        <div class="col-xs-12 noPadding plusAncho back-gran">
            <div class="container">
                <div class="col-md-12 openPrograms">
                <div class="formacionEmpresas">
                    <div class="title">Error 404 </div>
                    <span class="subtitle">The page you are looking for was not found</span>
                </div>
                <b-row style="float: left;">
                </b-row>
                </div>
            </div>
            <!-- Fin contenido -->
        </div>
        </b-row>

        <b-row class="clearfix" style="height: 100px; margin-top: -100px; background: #f3f3f3;"></b-row>

    </div>
</template>

<script>
    export default {
        metaInfo: {
            title: '404',
            meta: [
                // OpenGraph
                { vmid: "og:title", property: "og:title",  content: '404 - Club de Ejecutivos' },
                { vmid: "og:type", property: 'og:type', content: 'website' },
                // Google / Schema.org markup:
                { itemprop: 'name', content: '404 - Club de Ejecutivos' },
            ]
        }
    }
</script>

<style scoped>
    .title {
        color:white;
        font-size: 6rem;
        text-transform: uppercase;
    }
    .subtitle {
        color:white;
        font-size: 3rem;
    }

    .back-gran {
        width: 100%;
        backface-visibility: hidden;
        -webkit-backface-visibility: hidden;
        background: #009090;
        padding-top: 6%;
        padding-bottom: 6%;
        transform: skewY(2deg);
        -webkit-transform: skewY(2deg);
        margin-top: 40px;
        margin-bottom: 40px;
    }
    .openPrograms {
        transform: skewY(-2deg);
        -webkit-transform: skewY(-2deg);
    }
</style>